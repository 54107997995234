<template>
	<main class="main" id="main" v-bind:class="theme">
		<div class="mycelium">
			<img v-bind:src="mycelium" alt="the mycelium" title="The Mycelium" />
		</div>
		<!-- end mycelium -->
		<div class="flex-container">
			<!-- GET SIDEBAR -->
			<div class="sidebar__wrapper" id="sidebarWrapper">
				<Sidebar @nodeSelectedInSearch="nodeSelectedInSearch" :clientSlug="clientSlug"></Sidebar>
				<!-- GET NODE OVERVIEW -->
				<router-view @nodeOverviewClosed="nodeOverviewClosed" name="nodeOverview"></router-view>
				<!-- GET ADD NODE FORM -->
				<router-view @nodeRemoved="removeNode" @treeSaved="treeSaved" name="addNodeForm"></router-view>
			</div>
			<!-- end sidebar__wrapper -->
			<!-- MAIN CONTENT -->
			<div class="main-content">
				<div class="container">
					<router-view :key="viewRefreshKey" :clientSlug="clientSlug" @editNode="selectNode" @collapseSidebar="collapseSidebar" v-slot="{Component}">
						<component ref="clientView" :is="Component" />
					</router-view>
				</div>
				<!-- end container -->
			</div>
			<!-- end main-content -->
		</div>
		<!-- end flex-container -->
	</main>
</template>

<script>
import Sidebar from "./../components/sidebar/Sidebar.vue"

export default {
	data() {
		return {
			theme: "dashboard client has-sidebar blue-bg",
			mycelium: require("./../assets/img/mycelium.svg"),
			clientSlug: this.$route.params.client,
			viewRefreshKey: 0,
		}
	},
	methods: {
        nodeOverviewClosed(){
            this.$refs.clientView.$.ctx.fitToScreen()
        },
		nodeSelectedInSearch() {
			this.$refs.clientView.$.ctx.centerOnNode()
		},
		// selectNode() {
		// 	console.log("node selected, fired from client vue")
		// },
		async removeNode() {
			// console.log("node removed, registering emit in client.vue")
			await this.$store.dispatch("client/removeNode")
			++this.viewRefreshKey
		},
		async treeSaved() {
			// console.log("tree saved, registering emit in client.vue")
			await this.$store.dispatch("client/saveClientContentTree")
            ++this.viewRefreshKey
		},
		reRenderContentTree() {
			// console.log("re rendering tree!")
			++this.viewRefreshKey
		},
	},
	components: {
		Sidebar,
	},
}
</script>
<style lang="scss">
@import "./src/assets/scss/dashboard.scss";
</style>
